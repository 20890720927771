let timeLeft, openTimer
let timeDelay = null

// Wird über Lazy Load Script geladen
initMenuHoverHandler()

function initMenuHoverHandler() {
  const menuItemsWithDropDown = document.querySelectorAll(
    '.megamenu ul.navbar-nav > li.dropdown'
  )

  if (!menuItemsWithDropDown) return

  menuItemsWithDropDown.forEach((li) => {
    if (li.querySelectorAll('li').length) {
      let timer
      ;['mouseenter', 'touchend'].forEach(function (eventType) {
        // Mouse Enter Event
        li.addEventListener(eventType, () => {
          if (timeDelay === null) {
            openMenu(li)
            timeDelay = 160
          }

          const timeNow = new Date().getTime()
          const timeDiff = timeNow - timeLeft

          if (timeDiff < timeDelay) {
            clearTimeout(openTimer)
          }

          if (timeDelay > 0) {
            openTimer = setTimeout(() => {
              openMenu(li)
            }, timeDelay - timeDiff + 5)
          }

          // console.log(timeDiff)
          clearTimeout(timer)
        })
      })

      // Mouse Leave Event
      li.addEventListener('mouseleave', () => {
        timeLeft = new Date().getTime()

        timer = setTimeout(() => {
          closeMenu(li)
          // megaMenu.dataset.menuOpen = 'false'
        }, timeDelay)
      })
    }
  })
}

function openMenu(elt) {
  elt.classList.add('show')

  const subMenu = elt.querySelector('ul.dropdown-menu')
  subMenu.classList.add('show', 'position-absolute')
}

function closeMenu(elt) {
  elt.classList.remove('show')
  elt.querySelector('ul.dropdown-menu').classList.remove('show')
}
